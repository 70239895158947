// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academic-service-jsx": () => import("./../../../src/pages/academic_service.jsx" /* webpackChunkName: "component---src-pages-academic-service-jsx" */),
  "component---src-pages-album-jsx": () => import("./../../../src/pages/album.jsx" /* webpackChunkName: "component---src-pages-album-jsx" */),
  "component---src-pages-bio-jsx": () => import("./../../../src/pages/bio.jsx" /* webpackChunkName: "component---src-pages-bio-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-most-jsx": () => import("./../../../src/pages/most.jsx" /* webpackChunkName: "component---src-pages-most-jsx" */),
  "component---src-pages-project-jsx": () => import("./../../../src/pages/project.jsx" /* webpackChunkName: "component---src-pages-project-jsx" */),
  "component---src-pages-publication-jsx": () => import("./../../../src/pages/publication.jsx" /* webpackChunkName: "component---src-pages-publication-jsx" */),
  "component---src-pages-research-area-jsx": () => import("./../../../src/pages/research_area.jsx" /* webpackChunkName: "component---src-pages-research-area-jsx" */),
  "component---src-pages-teaching-jsx": () => import("./../../../src/pages/teaching.jsx" /* webpackChunkName: "component---src-pages-teaching-jsx" */)
}

